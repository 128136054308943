import { useStore } from '@/store/index';
import { useRouter } from 'vue-router';
import { defineNuxtRouteMiddleware } from '#app';

export default defineNuxtRouteMiddleware((to, from, next) => {
    var urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('invite')) {
        localStorage.setItem('openTeamInviteUuid', urlParams.get('invite'))
    }

    const hasToken = !!localStorage.getItem('authToken')
    if (!hasToken && !window.isAuthenticating) {
        const store = useStore();
        const router = useRouter();
        store.beforeLogout()
        console.log('is-authenticated redirect')
        return router.replace('/login');
    }
});
